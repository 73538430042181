import React from 'react';
import { ArrowLeft } from 'lucide-react';

const Terms = () => {
    return (
        <div className="min-h-screen bg-gray-900 text-white">
            {/* Header */}
            <header className="bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg fixed w-full z-10">
                <div className="container mx-auto flex justify-between items-center py-4 px-4">
                    <a href="/" className="flex items-center text-red-400 hover:text-red-300 transition duration-300">
                        <ArrowLeft className="w-5 h-5 mr-2" />
                        Back to Home
                    </a>
                    <h1 className="text-2xl font-bold">HotKiosk Terms and Conditions</h1>
                </div>
            </header>

            {/* Main Content */}
            <main className="container mx-auto pt-24 pb-12 px-4">
                <section className="max-w-3xl mx-auto bg-gray-800 rounded-lg shadow-xl p-8">
                    <h2 className="text-3xl font-bold mb-6">Terms and Conditions</h2>

                    <p className="mb-6">Last updated: September 26, 2024</p>

                    <div className="space-y-8">
                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">1. Acceptance of Terms</h3>
                            <p>By accessing or using the HotKiosk service, including our mobile application and website (collectively, the "Service"), you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access the Service.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">2. Changes to Terms</h3>
                            <p>We reserve the right to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">3. User Accounts</h3>
                            <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password. You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">4. Use License</h3>
                            <p>HotKiosk grants you a limited, non-exclusive, non-transferable, and revocable license to use the Service for your personal or business use in accordance with these Terms.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">5. User Content</h3>
                            <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, or other material ("Content"). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">6. Prohibited Uses</h3>
                            <p>You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:</p>
                            <ul className="list-disc list-inside ml-4 mt-2">
                                <li>In any way that violates any applicable national or international law or regulation.</li>
                                <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</li>
                                <li>To impersonate or attempt to impersonate HotKiosk, a HotKiosk employee, another user, or any other person or entity.</li>
                            </ul>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">7. Intellectual Property</h3>
                            <p>The Service and its original content (excluding Content provided by users), features, and functionality are and will remain the exclusive property of HotKiosk and its licensors. The Service is protected by copyright, trademark, and other laws of both Ghana and foreign countries.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">8. Termination</h3>
                            <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">9. Limitation of Liability</h3>
                            <p>In no event shall HotKiosk, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">10. Governing Law</h3>
                            <p>These Terms shall be governed and construed in accordance with the laws of Ghana, without regard to its conflict of law provisions.</p>
                        </section>

                        <section>
                            <h3 className="text-xl font-semibold mb-3 text-red-400">11. Contact Us</h3>
                            <p>If you have any questions about these Terms, please contact us at:</p>
                            <p className="mt-2">
                                Email: <a href="mailto:legal@hotkiosk.com" className="text-red-400 hover:text-red-300 transition duration-300">legal@hotkiosk.com</a>
                            </p>
                        </section>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <footer className="bg-gray-900 py-8">
                <div className="container mx-auto text-center text-gray-400">
                    <p>&copy; 2024 HotKiosk. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Terms;