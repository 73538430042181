import React, { useState, useEffect } from 'react';
import { BarChart2, Package, DollarSign, Coffee, Smartphone, Menu, X } from 'lucide-react';

const Home = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.pageYOffset);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            {/* Header */}
            <header className={`bg-black bg-opacity-${scrollPosition > 50 ? '90' : '50'} backdrop-filter backdrop-blur-lg fixed w-full z-10 transition-all duration-300`}>
                <div className="container mx-auto flex justify-between items-center py-4 px-4">
                    <div className="flex items-center">
                        <Coffee className="w-8 h-8 mr-2 text-red-500" />
                        <h1 className="text-2xl font-bold">HotKiosk</h1>
                    </div>
                    <nav className="hidden md:block">
                        <ul className="flex space-x-6">
                            <li><a href="#features" className="hover:text-red-400 transition duration-300">Features</a></li>
                            <li><a href="#mockups" className="hover:text-red-400 transition duration-300">App Preview</a></li>
                            <li><a href="#contact" className="hover:text-red-400 transition duration-300">Contact</a></li>
                        </ul>
                    </nav>
                    <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                    </button>
                </div>
                {isMenuOpen && (
                    <nav className="md:hidden bg-black bg-opacity-90 backdrop-filter backdrop-blur-lg">
                        <ul className="flex flex-col items-center py-4 space-y-4">
                            <li><a href="#features" className="hover:text-red-400 transition duration-300" onClick={() => setIsMenuOpen(false)}>Features</a></li>
                            <li><a href="#mockups" className="hover:text-red-400 transition duration-300" onClick={() => setIsMenuOpen(false)}>App Preview</a></li>
                            <li><a href="#contact" className="hover:text-red-400 transition duration-300" onClick={() => setIsMenuOpen(false)}>Contact</a></li>
                        </ul>
                    </nav>
                )}
            </header>

            {/* Hero Section */}
            <section className="bg-gradient-to-br from-red-600 to-yellow-600 pt-32 pb-20">
                <div className="container mx-auto text-center px-4">
                    <h2 className="text-4xl md:text-5xl font-bold mb-6 animate-fade-in-up">Ignite Your Business with HotKiosk</h2>
                    <p className="text-lg md:text-xl mb-10 animate-fade-in-up animation-delay-200">The hottest sales and inventory management app for small businesses in Ghana</p>
                    <button className="bg-white text-red-600 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition duration-300 transform hover:scale-105 animate-fade-in-up animation-delay-400">
                        Download Now
                    </button>
                </div>
            </section>

            {/* Features Section */}
            <section id="features" className="py-20 bg-gray-800">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Sizzling Features</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
                        <FeatureCard
                            icon={<BarChart2 className="w-12 h-12 text-red-500" />}
                            title="Hot Sales Tracking"
                            description="Easily record and analyze your daily sales with our user-friendly interface."
                        />
                        <FeatureCard
                            icon={<Package className="w-12 h-12 text-yellow-500" />}
                            title="Cool Inventory Management"
                            description="Keep your stock levels in check and never run out of popular items."
                        />
                        <FeatureCard
                            icon={<DollarSign className="w-12 h-12 text-green-500" />}
                            title="Sizzling Financial Reports"
                            description="Generate insightful reports to fuel your business decisions."
                        />
                    </div>
                </div>
            </section>

            {/* App Mockup Section */}
            <section id="mockups" className="py-20 bg-black">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">HotKiosk in Action</h2>
                    <div className="flex flex-col md:flex-row items-center justify-center space-y-12 md:space-y-0 md:space-x-16">
                        <div className="w-64 text-center transform hover:scale-105 transition duration-300">
                            <img src="/api/placeholder/300/600" alt="Android Mockup" className="rounded-3xl shadow-2xl mb-4" />
                            <p className="font-semibold text-red-400">Android Version</p>
                        </div>
                        <div className="w-64 text-center transform hover:scale-105 transition duration-300">
                            <img src="/api/placeholder/300/600" alt="iOS Mockup" className="rounded-3xl shadow-2xl mb-4" />
                            <p className="font-semibold text-red-400">iOS Version</p>
                        </div>
                        <div className="max-w-md mt-12 md:mt-0">
                            <h3 className="text-2xl font-bold mb-6">Manage Your Business on the Go</h3>
                            <ul className="space-y-4">
                                <li className="flex items-start">
                                    <Smartphone className="w-6 h-6 text-red-500 mr-3 mt-1 flex-shrink-0" />
                                    <span>Access your sales and inventory data anytime, anywhere</span>
                                </li>
                                <li className="flex items-start">
                                    <Smartphone className="w-6 h-6 text-yellow-500 mr-3 mt-1 flex-shrink-0" />
                                    <span>Intuitive interface designed for busy small business owners</span>
                                </li>
                                <li className="flex items-start">
                                    <Smartphone className="w-6 h-6 text-green-500 mr-3 mt-1 flex-shrink-0" />
                                    <span>Real-time updates keep your data synchronized across all devices</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Section */}
            <section id="contact" className="py-20 bg-gray-800">
                <div className="container mx-auto text-center px-4">
                    <h2 className="text-3xl md:text-4xl font-bold mb-8">Get in Touch</h2>
                    <p className="mb-8 text-xl">Have questions? Our team is ready to serve you hot!</p>
                    <a href="mailto:info@hotkiosk.com" className="text-red-400 hover:text-red-300 text-2xl font-semibold transition duration-300">
                        info@hotkiosk.com
                    </a>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-gray-900 py-8">
                <div className="container mx-auto px-4">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <p className="text-gray-400 mb-4 md:mb-0">&copy; 2024 HotKiosk. All rights reserved.</p>
                        <div className="flex space-x-4">
                            <a href="/privacy" className="text-gray-400 hover:text-white transition duration-300">Privacy Policy</a>
                            <a href="/terms" className="text-gray-400 hover:text-white transition duration-300">Terms of Service</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

const FeatureCard = ({ icon, title, description }) => {
    return (
        <div className="bg-gray-900 p-6 rounded-lg shadow-xl text-center transform hover:scale-105 transition duration-300">
            <div className="flex justify-center mb-4">{icon}</div>
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-400">{description}</p>
        </div>
    );
};

export default Home;