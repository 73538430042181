import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './app/pages/Home';
import Terms from './app/pages/Terms';
import Privacy from './app/pages/Privacy';

function App() {
  return (
    <BrowserRouter>
      <div className="bg-gray-900">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
